import { render, staticRenderFns } from "./AnonymousCheckout.vue?vue&type=template&id=52ba6ec0&"
import script from "./AnonymousCheckout.vue?vue&type=script&lang=js&"
export * from "./AnonymousCheckout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!C:/Projects/rentmagic.website.vue.limburg/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BCol, BFormInput, BFormGroup, BFormCheckbox, BRow, BAlert, BButton, BForm, BCard, BContainer} from 'bootstrap-vue'
    installComponents(component, {BCol, BFormInput, BFormGroup, BFormCheckbox, BRow, BAlert, BButton, BForm, BCard, BContainer})
    

export default component.exports